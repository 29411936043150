/* FOR RICH_TEXT INPUTS WHICH USE quilljs WYSIWYG */
/* IMPORTANT: these custom css rules should be in sync with the custom css on the main site which renders the generated HTML */

.ql-editor {
  line-height: 23px !important;
  font-size: 16px !important;
  background-color: #ffffff !important;
  font-family: "Graphik Web" !important;
  font-feature-settings: "liga", "kern" !important;
  border: 1px solid #222222 !important;
  min-height: 100px !important;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0 !important;
  padding: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}
.ql-editor p:not(:last-child),
.ql-editor h1:not(:last-child),
.ql-editor h2:not(:last-child),
.ql-editor h3:not(:last-child) {
  margin-bottom: 16px !important;
}
.ql-editor h1:first-child,
.ql-editor h2:first-child,
.ql-editor h3:first-child {
  margin-top: 0 !important;
}
.ql-editor strong {
  font-weight: 600 !important;
}

/* p:has(br) {
  line-height: 0 !important;
} */

/* h1 */
.ql-editor h1 {
  font-weight: 600 !important;
  font-size: 32px !important;
  letter-spacing: 0.4px !important;
  line-height: 40px !important;
}
.ql-editor h1.ql-size-small {
  font-size: 24px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
  line-height: 30px !important;
}
.ql-editor h1.ql-size-large {
  font-size: 40px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 50px !important;
}
.ql-editor h1.ql-size-huge {
  font-size: 56px !important;
  font-weight: 600 !important;
  letter-spacing: 0.7px !important;
  line-height: 70px !important;
}

/* h2 */
.ql-editor h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
  line-height: 30px !important;
}
.ql-editor h2.ql-size-small {
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  line-height: 26px !important;
}
.ql-editor h2.ql-size-large {
  font-size: 32px !important;
  font-weight: 600 !important;
  letter-spacing: 0.4px !important;
  line-height: 40px !important;
}
.ql-editor h2.ql-size-huge {
  font-size: 48px !important;
  font-weight: 600 !important;
  letter-spacing: 0.6px !important;
  line-height: 62px !important;
}

/* h3 */
.ql-editor h3 {
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0.25px !important;
  line-height: 26px !important;
}
.ql-editor h3.ql-size-small {
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0.2px !important;
  line-height: 23px !important;
}
.ql-editor h3.ql-size-large {
  font-size: 24px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
  line-height: 30px !important;
}
.ql-editor h3.ql-size-huge {
  font-size: 40px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 50px !important;
}

/* regular */
.ql-editor .ql-size-small {
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 21px !important;
}
.ql-editor .ql-size-large {
  font-size: 20px !important;
  letter-spacing: 0 !important;
  line-height: 26px !important;
}
.ql-editor .ql-size-huge {
  font-size: 32px !important;
  letter-spacing: 0 !important;
  line-height: 40px !important;
}

@media only screen and (max-width: 960px) {
  /* h1 */
  .ql-editor h1 {
    font-size: 24px !important;
    font-weight: 600 !important;
    letter-spacing: 0.3px !important;
    line-height: 30px !important;
  }
  .ql-editor h1.ql-size-small {
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0.25px !important;
    line-height: 26px !important;
  }
  .ql-editor h1.ql-size-large {
    font-size: 32px !important;
    font-weight: 600 !important;
    letter-spacing: 0.4px !important;
    line-height: 40px !important;
  }
  .ql-editor h1.ql-size-huge {
    font-size: 40px !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
    line-height: 50px !important;
  }

  /* h2 */
  .ql-editor h2 {
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0.25px !important;
    line-height: 26px !important;
  }
  .ql-editor h2.ql-size-small {
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.2px !important;
    line-height: 23px !important;
  }
  .ql-editor h2.ql-size-large {
    font-size: 24px !important;
    font-weight: 600 !important;
    letter-spacing: 0.3px !important;
    line-height: 30px !important;
  }
  .ql-editor h2.ql-size-huge {
    font-size: 32px !important;
    font-weight: 600 !important;
    letter-spacing: 0.4px !important;
    line-height: 40px !important;
  }

  /* h3 */
  .ql-editor h3 {
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.2px !important;
    line-height: 23px !important;
  }
  .ql-editor h3.ql-size-small {
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0.17px !important;
    line-height: 21px !important;
  }
  .ql-editor h3.ql-size-large {
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 0.25px !important;
    line-height: 26px !important;
  }
  .ql-editor h3.ql-size-huge {
    font-size: 24px !important;
    font-weight: 600 !important;
    letter-spacing: 0.3px !important;
    line-height: 30px !important;
  }

  /* regular */
  .ql-editor .ql-size-small {
    font-size: 12px !important;
    letter-spacing: 0 !important;
    line-height: 19px !important;
  }
  .ql-editor .ql-size-large {
    font-size: 20px !important;
    letter-spacing: 0 !important;
    line-height: 23px !important;
  }
  .ql-editor .ql-size-huge {
    font-size: 24px !important;
    letter-spacing: 0 !important;
    line-height: 30px !important;
  }
}

.ql-editor .ql-bg-black {
  background-color: #000 !important;
}
.ql-editor .ql-bg-red {
  background-color: #e60000 !important;
}
.ql-editor .ql-bg-orange {
  background-color: #f90 !important;
}
.ql-editor .ql-bg-yellow {
  background-color: #ff0 !important;
}
.ql-editor .ql-bg-green {
  background-color: #008a00 !important;
}
.ql-editor .ql-bg-blue {
  background-color: #06c !important;
}
.ql-editor .ql-bg-purple {
  background-color: #93f !important;
}
.ql-editor .ql-color-white {
  color: #fff !important;
}
.ql-editor .ql-color-red {
  color: #e60000 !important;
}
.ql-editor .ql-color-orange {
  color: #f90 !important;
}
.ql-editor .ql-color-yellow {
  color: #ff0 !important;
}
.ql-editor .ql-color-green {
  color: #008a00 !important;
}
.ql-editor .ql-color-blue {
  color: #06c !important;
}
.ql-editor .ql-color-purple {
  color: #93f !important;
}
.ql-editor .ql-direction-rtl {
  direction: rtl !important;
  text-align: inherit !important;
}
.ql-editor .ql-align-center {
  text-align: center !important;
}
.ql-editor .ql-align-justify {
  text-align: justify !important;
}
.ql-editor .ql-align-right {
  text-align: right !important;
}
.ql-editor a {
  color: #222222 !important;
}
