html {
  overflow: auto;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: "Graphik Web", "sans-serif", "Roboto", "Helvetica", "Arial";
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #fafafa;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

@media (max-width: 959.95px) {
  html {
    overscroll-behavior-y: auto;
  }
  body {
    overscroll-behavior-y: auto;
  }
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
